import { SvgIcon } from '@mui/material';

const PersonChalkboard = props => {
    return (
        <SvgIcon viewBox="0 0 14 12" {...props}>
            <path d="M7 2.8V1.05C7 0.470094 7.47031 0 8.05 0H12.95C13.5297 0 14 0.470094 14 1.05V5.95C14 6.52969 13.5297 7 12.95 7H8.05C7.47031 7 7 6.52969 7 5.95V4.9H8.4V5.6H12.6V1.4H8.4V2.8H7Z" />
            <path
                className="secondary"
                d="M5.24998 1.05C5.24998 1.62991 4.77967 2.1 4.19998 2.1C3.62029 2.1 3.14998 1.62991 3.14998 1.05C3.14998 0.470094 3.62029 0 4.19998 0C4.77967 0 5.24998 0.470094 5.24998 1.05ZM4.02498 7.7V10.5C4.02498 10.8872 3.71217 11.2 3.32498 11.2C2.93779 11.2 2.62498 10.8872 2.62498 10.5V5.61969L2.00001 6.66094C1.80095 6.99125 1.37089 7.09844 1.03948 6.89937C0.708074 6.70031 0.600887 6.27156 0.800168 5.93906L2.07482 3.81938C2.45436 3.18719 3.13686 2.8 3.87404 2.8H8.74998C9.13717 2.8 9.44998 3.11281 9.44998 3.5C9.44998 3.88719 9.13717 4.2 8.74998 4.2H5.77498V10.5C5.77498 10.8872 5.46217 11.2 5.07498 11.2C4.68779 11.2 4.37498 10.8872 4.37498 10.5V7.7H4.02498Z"
            />
        </SvgIcon>
    );
};

export default PersonChalkboard;
