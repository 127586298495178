import { SvgIcon } from '@mui/material';

const Pages = props => {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path d="M5.625 2.25C5.625 1.00898 6.63398 -4.41041e-08 7.875 -9.83506e-08L15.75 -4.42578e-07C16.991 -4.96824e-07 18 1.00898 18 2.25L18 15.75C18 16.9928 16.991 18 15.75 18L7.875 18C6.63398 18 5.625 16.9928 5.625 15.75L5.625 2.25Z" />
            <path
                d="M4.5 2.53125L4.5 15.4687C4.5 15.9346 4.12383 16.3125 3.65625 16.3125C3.19043 16.3125 2.8125 15.9346 2.8125 15.4687L2.8125 2.53125C2.8125 2.06367 3.19043 1.6875 3.65625 1.6875C4.12383 1.6875 4.5 2.06367 4.5 2.53125ZM1.6875 4.21875L1.6875 13.7812C1.6875 14.2488 1.30957 14.625 0.84375 14.625C0.37793 14.625 -9.0206e-08 14.2488 -1.10644e-07 13.7812L-5.28635e-07 4.21875C-5.49073e-07 3.75117 0.377929 3.375 0.843749 3.375C1.30957 3.375 1.6875 3.75117 1.6875 4.21875Z"
                className="secondary"
            />
        </SvgIcon>
    );
};

export default Pages;
