import { createContext, useReducer } from 'react';
import LoadingScreen from 'components/LoadingScreen';

import axiosInstance from '../utils/axios';
import { baseUrl } from 'utils/constants';
import { localSet } from 'utils/utils';

// --------------------------------------------------------
const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
}; // const isValidToken = (accessToken: string) => {
//   if (!accessToken) return false;
//   // const decodedToken = jwtDecode<{ exp: number }>(accessToken);
//   const currentTime = Date.now() / 1000;
//   // return decodedToken.exp > currentTime;
// };
// const setSession = (accessToken: string | null) => {
//   if (accessToken) {
//     // localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     // localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const request = (method, url, param) => {
    return axiosInstance[method](`${baseUrl}${url}`, param).then(r => {
        return r.data;
    });
};

const reducer = (state, action) => {
    switch (action.type) {
        case `INIT`: {
            return {
                isInitialized: true,
                user: action.payload.user,
                isAuthenticated: action.payload.isAuthenticated,
            };
        }

        case `LOGIN`: {
            return { ...state, isAuthenticated: true, user: action.payload.user };
        }

        case `LOGOUT`: {
            return { ...state, user: null, isAuthenticated: false };
        }

        case `REGISTER`: {
            return { ...state, isAuthenticated: true, user: action.payload.user };
        }

        default: {
            return state;
        }
    }
};

const AuthContext = createContext({
    ...initialState,
    method: `JWT`,
    logout: () => {},
    login: () => {},
    forgot: () => {},
});
export const JWTAuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const login = (email, password, fpwdid) => {
        return grecaptcha
            .execute(`6LesMmYbAAAAACONR1kp2flqwlel9cK6gJXDvbkx`, { action: `login` })
            .then(recaptchaToken => {
                return request(`post`, `login`, {
                    email,
                    password,
                    recaptchaToken,
                    ...(fpwdid && { forgot: fpwdid }),
                }).then(({ sid: newSid }) => {
                    localSet(`sid`, newSid);
                });
            });
    };

    const logout = () => {
        // setSession(null);
        dispatch({
            type: `LOGOUT`,
        });
    };

    const forgot = email =>
        grecaptcha.execute(`6LesMmYbAAAAACONR1kp2flqwlel9cK6gJXDvbkx`, { action: `login` }).then(recaptchaToken => {
            return request(`post`, `login`, {
                email,
                forgot: 1,
                recaptchaToken,
            }).then(() => true);
        });

    if (!state.isInitialized) <LoadingScreen />;
    return (
        <AuthContext.Provider value={{ ...state, method: `JWT`, login, forgot, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContext;
