import { AppBar, Box, IconButton, styled, Toolbar, useMediaQuery } from '@mui/material';
import { SettingsContext } from 'contexts/settingsContext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ThemeIcon from 'icons/ThemeIcon';
import { useContext, useEffect, useMemo } from 'react';
import ProfilePopover from './popovers/ProfilePopover';
import EMStyled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

// ------------------------------------------------
// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
    zIndex: 11,
    boxShadow: `none !important`,
    backgroundColor: `transparent`,
    borderRadius: `0 0 10px 10px`,
    position: `static`,
    padding: `1rem`,
    backdropFilter: `blur(6px)`,
    color: theme.palette.text.primary,
}));
const StyledToolBar = styled(Toolbar)(() => ({
    '@media (min-width: 0px)': {
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: `auto`,
    },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));
const ToggleIcon = styled(Box)(({ theme, width }) => ({
    height: 3,
    margin: `5px`,
    marginLeft: 0,
    width: width || 25,
    borderRadius: `10px`,
    transition: `width 0.3s`,
    backgroundColor: theme.palette.primary.main,
}));
const IconWrapper = EMStyled.div`
    margin-top: 8px;
    cursor: pointer;
`;

const backButtonLocations = [{ l: `/dashboard/member`, d: `/dashboard/research/members` }];

const DashboardHeader = props => {
    const { setShowMobileSideBar } = props;
    const { settings, saveSettings } = useContext(SettingsContext);
    const location = useLocation();
    const downMd = useMediaQuery(theme => theme.breakpoints.down(`lg`));
    const navigate = useNavigate();
    const backLocation = useMemo(() => {
        const loc = backButtonLocations.find(b => location.pathname.startsWith(b.l));
        return loc?.d;
    }, [location.pathname]);
    useEffect(() => {
        const elem = document.getElementsByClassName(`grecaptcha-badge`)?.[0];
        if ([`/login`, `/forget-password`].includes(location.pathname) && elem) {
            elem.style.visibility = `visible`;
        } else if (elem) {
            elem.style.visibility = `hidden`;
        }
        const sid = localStorage.getItem(`sid`);
        if (!sid && location.pathname !== `/login`) {
            navigate(`/login`);
        } //eslint-disable-next-line
    }, [location.pathname]);

    const handleChangeTheme = value => {
        saveSettings({ ...settings, theme: value });
    };

    return (
        <DashboardHeaderRoot sx={{ padding: `16px 0 !important` }}>
            <StyledToolBar>
                {downMd && (
                    <Box
                        sx={{
                            cursor: `pointer`,
                            marginRight: 3,
                        }}
                        onClick={setShowMobileSideBar}
                    >
                        <ToggleIcon />
                        <ToggleIcon width={18} />
                        <ToggleIcon width={9} />
                    </Box>
                )}
                {!!backLocation && (
                    <IconWrapper onClick={() => navigate(backLocation)}>
                        <NavigateBeforeIcon />
                    </IconWrapper>
                )}

                <Box flexGrow={1} ml={1} />

                {settings.theme === `light` ? (
                    <StyledIconButton onClick={() => handleChangeTheme(`dark`)}>
                        <ThemeIcon />
                    </StyledIconButton>
                ) : (
                    <StyledIconButton onClick={() => handleChangeTheme(`light`)}>
                        <ThemeIcon />
                    </StyledIconButton>
                )}

                <ProfilePopover />
            </StyledToolBar>
        </DashboardHeaderRoot>
    );
};

export default DashboardHeader;
