import duotone from 'icons/duotone';
import { PersonSearchTwoTone, LocalOfferTwoTone } from '@mui/icons-material';

export const navigations = [
    {
        type: `label`,
        label: `Management`,
    },
    /** {
    name: `Home`,
    path: `/dashboard`,
    icon: duotone.PersonChalkboard,
  }, */
    {
        name: `Research`,
        icon: PersonSearchTwoTone,
        path: [`/dashboard/research`, `/dashboard/member`],
    },
    {
        name: `Offers`,
        icon: LocalOfferTwoTone,
        path: `/dashboard/offers/findoffer`,
    },
    {
        name: `Reports`,
        icon: duotone.Pages,
        path: `/dashboard/reports`,
    },
    {
        name: `Health`,
        icon: duotone.Settings,
        path: `/dashboard/health`,
        liOnly: true,
    },
    {
        name: `Payouts`,
        icon: duotone.Pricing,
        path: `/dashboard/payouts`,
        liOnly: true,
    },
];
