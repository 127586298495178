const USDollar = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
});

export const searchByName = (listData, searchValue) => {
    if (searchValue.length > 0) {
        const searchResult = listData.filter(item => item.name.toLocaleLowerCase().match(searchValue.toLowerCase()));
        return searchResult;
    }
    return listData;
};
export const getRoute = pathname => {
    const str = pathname.split(`/`);
    return `/${str[1]}`;
};
export const localSet = (key, item) => {
    localStorage.setItem(key, item);
    window.dispatchEvent(new Event(`storage`));
};

export const clearLocalStorage = () => {
    const settings = localStorage.getItem(`settings`);
    localStorage.clear();
    if (settings) {
        localStorage.setItem(`settings`, settings);
    }
};

export const dayTime = () => {
    var curHr = new Date().getHours();

    if (curHr < 12) {
        return `morning`;
    } else if (curHr < 18) {
        return `afternoon`;
    } else {
        return `evening`;
    }
};

export const formatMoney = v => USDollar.format(v);

export const formatDate = (d, opts) =>
    new Date(d)
        .toLocaleDateString(`en-US`, {
            month: `2-digit`,
            day: `2-digit`,
            year: opts?.shortDate ? `2-digit` : `numeric`,
        })
        .replace(/\//g, `-`);

export const getMonthName = monthNumber => {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString(`default`, { month: `long` });
};
