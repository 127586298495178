import { SvgIcon } from '@mui/material';

const Apps = props => {
    return (
        <SvgIcon viewBox="0 0 20 16" {...props}>
            <path d="M12.6874 5.0623H5.37518C4.91114 5.0623 4.53146 5.44197 4.53146 5.90602C4.53146 6.37006 4.91114 6.74974 5.37518 6.74974H12.6874C13.155 6.74974 13.5311 6.37358 13.5311 5.90602C13.5311 5.43846 13.155 5.0623 12.6874 5.0623ZM9.31253 8.43717H5.37518C4.91114 8.43717 4.53146 8.81684 4.53146 9.28089C4.53146 9.74493 4.91114 10.1246 5.37518 10.1246H9.31253C9.78009 10.1246 10.1562 9.74845 10.1562 9.28089C10.1562 8.81333 9.78009 8.43717 9.31253 8.43717Z" />
            <path
                d="M15.7494 0H2.24991C1.0107 0 0 1.0107 0 2.21827V12.3112C0 13.5505 1.0107 14.5295 2.24991 14.5295H5.62478V17.4509C5.62478 17.7936 6.02027 17.994 6.29694 17.7919L10.6878 14.4979H15.7501C16.9893 14.4979 18 13.4872 18 12.2796V2.21827C17.9993 1.0107 17.0185 0 15.7494 0ZM9.31253 10.1246H5.37518C4.91114 10.1246 4.53146 9.74845 4.53146 9.28089C4.53146 8.81333 4.91114 8.43717 5.37518 8.43717H9.31253C9.78009 8.43717 10.1562 8.81685 10.1562 9.28089C10.1562 9.74493 9.78009 10.1246 9.31253 10.1246ZM12.6874 6.74974H5.37518C4.91114 6.74974 4.53146 6.37358 4.53146 5.90602C4.53146 5.43846 4.91114 5.0623 5.37518 5.0623H12.6874C13.155 5.0623 13.5311 5.44198 13.5311 5.90602C13.5311 6.37007 13.155 6.74974 12.6874 6.74974Z"
                className="secondary"
            />
        </SvgIcon>
    );
};

export default Apps;
