import { Box, ButtonBase, Divider, styled } from '@mui/material';

import { Small } from 'components/Typography';
import useAuth from 'hooks/useAuth';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PopoverLayout from './PopoverLayout'; // styled components
import { dayTime } from 'utils/utils';
import { baseUrl } from 'utils/constants';

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    padding: 5,
    marginLeft: 4,
    borderRadius: 30,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));
const StyledSmall = styled(Small)(({ theme }) => ({
    display: `block`,
    cursor: `pointer`,
    padding: `5px 1rem`,
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const greeting = () => `Good ${dayTime()}, ${localStorage.getItem(`firstName`) || localStorage.getItem(`email`) || ``}`;
const ProfilePopover = () => {
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [open, setOpen] = useState(false);
    const [undefined, setRefresh] = useState(Date.now());

    const listenerUpdate = () => {
        setRefresh(Date.now());
    };

    useEffect(() => {
        window.addEventListener(`storage`, listenerUpdate);
        return () => window.removeEventListener(`storage`, listenerUpdate);
    }, []);

    const handleLogout = () => {
        logout();
        navigate(`/login`);
    };

    return (
        <Fragment>
            <StyledButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
                <Small mx={1} color="text.secondary">
                    {greeting()}
                </Small>
            </StyledButtonBase>

            <PopoverLayout
                hiddenViewButton
                popoverOpen={open}
                anchorRef={anchorRef}
                popoverClose={() => setOpen(false)}
            >
                <Box pt={1}>
                    {!!localStorage.getItem(`isLi`) && (
                        <>
                            <StyledSmall>
                                <a rel="noopener" href={`${baseUrl}adminlogin?token=${localStorage.getItem(`sid`)}`}>
                                    LI Admin
                                </a>
                            </StyledSmall>
                            <Divider
                                sx={{
                                    my: 1,
                                }}
                            />
                        </>
                    )}
                    <StyledSmall
                        onClick={() => {
                            handleLogout();
                            toast.success(`Successfully Logged Out`);
                        }}
                    >
                        Sign Out
                    </StyledSmall>
                </Box>
            </PopoverLayout>
        </Fragment>
    );
};

export default ProfilePopover;
