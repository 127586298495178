import { Box, FormControl, InputLabel, MenuItem, Select, styled, useTheme } from '@mui/material';
import emStyled from '@emotion/styled';
import FlexBox from 'components/flexbox/FlexBox';
import LayoutDrawer from 'layouts/layout-parts/LayoutDrawer';
import MultiLevelMenu from './MultiLevelMenu';
import { useEffect, useState } from 'react';
import axiosInstance from 'utils/axios';
import { baseUrl } from 'utils/constants';
import { clearLocalStorage, localSet } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import FlexBetween from 'components/flexbox/FlexBetween';
const TOP_HEADER_AREA = 70;
const NavWrapper = styled(Box)(() => ({
    paddingLeft: 16,
    paddingRight: 16,
    height: `100%`,
}));

const LogoImg = emStyled.img`
    height: 40px;
    margin: 10px 0 0 0;
    width:auto;
`;

const MobileSidebar = props => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [config, setConfig] = useState(JSON.parse(localStorage.getItem(`config`) || JSON.stringify({})));
    const [allPrograms, setAllPrograms] = useState(
        JSON.parse(localStorage.getItem(`allPrograms`) || JSON.stringify([]))
    );
    const [selectedProgram, setSelectedProgram] = useState(localStorage.getItem(`selectedProgram`));

    const listenerUpdate = () => {
        setAllPrograms(JSON.parse(localStorage.getItem(`allPrograms`) || JSON.stringify([])));
        setSelectedProgram(localStorage.getItem(`selectedProgram`));
        setConfig(JSON.parse(localStorage.getItem(`config`) || JSON.stringify({})));
    };

    const switchProgram = v =>
        axiosInstance
            .get(`${baseUrl}switchme?guid=${v}`)
            .then(r => {
                Object.keys(r.data).forEach(i => {
                    localSet(i, r.data[i]);
                });
            })
            .catch(() => {
                clearLocalStorage();
                navigate(`/login`);
            });

    useEffect(() => {
        window.addEventListener(`storage`, listenerUpdate);
        return () => window.removeEventListener(`storage`, listenerUpdate);
    }, []);

    const Separator = emStyled.div`
  height: 40px;width: 1px; 
  background-color: ${theme.palette.divider};
  margin: 10px 45px;`;

    const { sidebarCompact, showMobileSideBar, setShowMobileSideBar } = props;
    return (
        <LayoutDrawer open={showMobileSideBar} onClose={setShowMobileSideBar}>
            <Box p={2} maxHeight={TOP_HEADER_AREA}>
                <FlexBox ml={1.5}>
                    <LogoImg src="/static/logo/lilogo.svg" alt="logo" width={18} />
                    <Separator />

                    <LogoImg alt="" src={config?.li_dashboard?.headerlogo || ``} />
                </FlexBox>
            </Box>

            {!!allPrograms.length && (
                <FlexBetween pt={3} pr={2} pl={4} pb={1} height={TOP_HEADER_AREA} fullWidth>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Selected Program</InputLabel>
                        <Select
                            sx={{
                                '&:hover': { background: `#F7F7F7` },
                                '& div ': { padding: `7px 12px !important` },
                                minHeight: `unset !important`,
                                borderRadius: `20px !important`,
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Selected Program"
                            fullWidth
                            value={selectedProgram}
                            onChange={v => switchProgram(v.target.value)}
                        >
                            {localStorage.programs}
                            {allPrograms.map(a => (
                                <MenuItem value={a.programGuid}>{a.programName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FlexBetween>
            )}

            <NavWrapper compact={sidebarCompact}>
                <MultiLevelMenu sidebarCompact={false} setShowMobileSideBar={setShowMobileSideBar} />
            </NavWrapper>
        </LayoutDrawer>
    );
};

export default MobileSidebar;
