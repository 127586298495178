import { Box, styled } from '@mui/material';
import { Fragment } from 'react';

const Wrapper = styled(Box)(({ theme }) => ({
    paddingLeft: `3rem`,
    paddingRight: `3rem`,
    transition: `all 0.3s`,
    [theme.breakpoints.down(`lg`)]: {
        marginLeft: 0,
        paddingLeft: `2rem`,
        paddingRight: `2rem`,
    },
    [theme.breakpoints.down(`sm`)]: {
        marginLeft: 0,
        paddingLeft: `5px`,
        paddingRight: `5px`,
    },
}));
const InnerWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up(`lg`)]: {
        maxWidth: 1300,
        margin: `auto`,
    },
}));

const LayoutBodyWrapper = ({ children, sx }) => {
    return (
        <Fragment>
            <Wrapper sx={sx}>
                <InnerWrapper>{children}</InnerWrapper>
            </Wrapper>
        </Fragment>
    );
};

export default LayoutBodyWrapper;
