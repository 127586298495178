import AdminEcommerce from './AdminEcommerce';
import Dashboard from './Dashboard';
import Ecommerce from './Ecommerce';
import ElementHub from './ElementHub';
import Logout from './Logout';
import ProjectChart from './ProjectChart';
import Settings from './Settings';
import UserProfile from './UserProfile';
import TableList from './DataTable';
import Accounts from './Accounts';
import Calender from './Calender';
import Chat from './Chat';
import Invoice from './Invoice';
import TodoList from './TodoList';
import Session from './Session';
import Pages from './Pages';
import Pricing from './Pricing';
import DataTable from './DataTable';
import ArrowLeftToLine from './ArrowLeftToLine';
import MessagesDollar from './MessagesDollar';
import BadgeDollar from './BadgeDollar';
import PersonChalkboard from './PersonChalkboard';
import CommentsQuestionCheck from './CommentsQuestionCheck';
import PersonCircleCheck from './PersonCircleCheck';
import RectangleCirclePlus from './RectangleCirclePlus';
import DiagramProject from './DiagramProject';
import LayerGroup from './LayerGroup';
import FileCircleQuestion from './FileCircleQuestion';
import UserList from './UserList';

const collection = {
    AdminEcommerce,
    ArrowLeftToLine,
    CommentsQuestionCheck,
    Dashboard,
    DiagramProject,
    Ecommerce,
    ElementHub,
    FileCircleQuestion,
    Logout,
    ProjectChart,
    Settings,
    UserProfile,
    UserList,
    TableList,
    Accounts,
    Calender,
    Chat,
    Invoice,
    TodoList,
    Session,
    Pages,
    Pricing,
    DataTable,
    LayerGroup,
    MessagesDollar,
    BadgeDollar,
    RectangleCirclePlus,
    PersonChalkboard,
    PersonCircleCheck,
};

export default collection;
