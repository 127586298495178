import { SvgIcon } from '@mui/material';

const PersonCircleCheck = props => {
    return (
        <SvgIcon viewBox="0 0 14 12" {...props}>
            <path d="M7 8.94434C7 7.01204 8.56771 5.44434 10.5 5.44434C12.4323 5.44434 14 7.01204 14 8.94434C14 10.8766 12.4323 12.4443 10.5 12.4443C8.56771 12.4443 7 10.8766 7 8.94434ZM12.1358 8.44121C12.2889 8.29052 12.2889 8.0426 12.1358 7.89191C11.9851 7.73878 11.7372 7.73878 11.5865 7.89191L10.1111 9.36725L9.41354 8.66968C9.26285 8.51656 9.01493 8.51656 8.86424 8.66968C8.71111 8.82038 8.71111 9.06829 8.86424 9.21899L9.83646 10.1912C9.98715 10.3443 10.2351 10.3443 10.3858 10.1912L12.1358 8.44121Z" />
            <path
                className="secondary"
                d="M2.72219 1.16667C2.72219 0.522326 3.24476 0 3.88886 0C4.53296 0 5.05553 0.522326 5.05553 1.16667C5.05553 1.81101 4.53296 2.33333 3.88886 2.33333C3.24476 2.33333 2.72219 1.81101 2.72219 1.16667ZM2.91664 12.4444C2.48643 12.4444 2.13886 12.0969 2.13886 11.6667V6.2441L1.44445 7.40104C1.22327 7.76806 0.745424 7.88715 0.377194 7.66597C0.00895549 7.44479 -0.110156 6.9684 0.111121 6.59896L1.52758 4.24375C1.94928 3.54132 2.70761 3.11111 3.52671 3.11111H4.25101C5.07011 3.11111 5.82844 3.54132 6.25136 4.24375L7.34268 6.05938C6.92949 6.48715 6.64511 6.99271 6.4531 7.55417C6.40692 7.50799 6.34615 7.45694 6.334 7.40104L5.63886 6.2441V11.6667C5.63886 12.0969 5.29129 12.4444 4.86108 12.4444C4.43087 12.4444 4.08331 12.0969 4.08331 11.6667V8.55556H3.69442V11.6667C3.69442 12.0969 3.34685 12.4444 2.91664 12.4444Z"
            />
        </SvgIcon>
    );
};

export default PersonCircleCheck;
