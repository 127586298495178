import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Toaster } from 'react-hot-toast';
import RTL from 'components/RTL';
import useSettings from 'hooks/useSettings';
import { createCustomTheme } from './theme';
import routes from './routes';
import './i18n';
import './base.css';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    `fd2cbc5bbe13c001a6dff7e6fe03486dTz04NTMxNCxFPTE3NDA4MDA2NTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=`
);

const App = () => {
    const router = createBrowserRouter(routes());
    const { settings } = useSettings();
    const theme = createCustomTheme({
        theme: settings.theme,
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RTL>
                    <CssBaseline />
                    <Toaster position="bottom-left" />
                    <RouterProvider router={router} />
                </RTL>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
