import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
    config => {
        const r = config;
        r.headers.Authorization = `Bearer ${localStorage.getItem(`sid`) ?? `  `}`;
        return r;
    },
    error => Promise.reject(error)
);
axiosInstance.interceptors.response.use(
    response => response,
    error => Promise.reject(error?.message || `Something went wrong`)
);
export default axiosInstance;
