import { lazy, Suspense, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';

import LayoutV3 from 'layouts/layout/DashboardLayout';
import axiosInstance from 'utils/axios';
import { baseUrl } from 'utils/constants';
import { clearLocalStorage, localSet } from 'utils/utils';

const Loadable = Component => props => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
}; // dashboards

const Health = Loadable(lazy(() => import(`./pages/data-table/health`))); // invoice
const ErrorLogs = Loadable(lazy(() => import(`./pages/dashboard/health/logs`)));
const Research = Loadable(lazy(() => import(`./pages/dashboard/research/research`)));
const Payouts = Loadable(lazy(() => import(`./pages/dashboard/payouts/payouts`)));
const PersonView = Loadable(lazy(() => import(`./pages/dashboard/research/member`)));
const OffersView = Loadable(lazy(() => import(`./pages/dashboard/offerManagement/offerManagement`)));
const Login = Loadable(lazy(() => import(`./pages/authentication/login`)));
const ForgotPassword = Loadable(lazy(() => import(`./pages/authentication/forget-password`)));
const Reports = Loadable(lazy(() => import(`./pages/dashboard/reports/reports`))); // chat
const Error = Loadable(lazy(() => import(`./pages/404`)));
const ActiveLayout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (window.location.pathname !== `/login`) {
            axiosInstance
                .get(`${baseUrl}userinfo`)
                .then(r => {
                    clearLocalStorage();
                    Object.keys(r.data).forEach(i => {
                        localSet(i, r.data[i]);
                    });
                })
                .catch(() => {
                    clearLocalStorage();
                    navigate(`/login`);
                });
        }
    }, [navigate]);

    return <LayoutV3 />;
};

const dashboardRoutes = [
    {
        path: `/dashboard/health`,
        element: <Health />,
    },
    {
        path: `/dashboard/health/errorlogs`,
        element: <ErrorLogs />,
    },
    {
        path: `/dashboard/payouts/:month?/:year?`,
        element: <Payouts />,
    },
    {
        path: `/dashboard/research/:tab?/:search?`,
        element: <Research />,
    },
    {
        path: `/dashboard/member/:numGuid/:tab?/:search?`,
        element: <PersonView />,
    },
    {
        path: `/dashboard/member/:numGuid/:tab/:sid/inquire`,
        element: <PersonView />,
    },
    {
        path: `/dashboard/orders/:numGuid/:tab?/:search?`,
        element: <PersonView />,
    },
    {
        path: `/dashboard/offers/:tab?/:options?`,
        element: <OffersView />,
    },
    {
        path: `/dashboard/reports/:tab?`,
        element: <Reports />,
    },
];

const authRoutes = [
    {
        path: `/`,
        element: <Navigate to="/dashboard/research/members" />,
    },
    {
        path: `login`,
        element: <Login />,
    },

    {
        path: `forget-password`,
        element: <ForgotPassword />,
    },
];

const routes = () => {
    return [
        ...authRoutes,
        {
            path: `dashboard`,
            element: <ActiveLayout />,
            children: dashboardRoutes,
        },
        {
            path: `*`,
            element: <Error />,
        },
    ];
};

export default routes;
