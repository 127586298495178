import { Popover } from '@mui/material';
import React from 'react'; // component props interface

const PopoverLayout = props => {
    const {
        children,
        popoverClose,
        popoverOpen,
        anchorRef,

        minWidth,
        maxWidth,
    } = props;
    return (
        <Popover
            open={popoverOpen}
            onClose={popoverClose}
            anchorEl={anchorRef.current}
            anchorOrigin={{
                horizontal: `center`,
                vertical: `bottom`,
            }}
            PaperProps={{
                sx: {
                    padding: `0.5rem`,
                },
            }}
        >
            {children}
        </Popover>
    );
};

export default PopoverLayout;
